import React, { Component } from 'react'
import './Inscripcion3.css';
import fondo from '../images/banner2.png';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import monito from '../images/monito3.png';

export default class Inscripcion3 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="jumbotron-fluid paral fondo-cuadros" style={{ backgroundImage: 'url(' + fondo + ')', paddingTop: "40px", paddingBottom: "40px" }} >
                <div className="container">
                    <div className="card" style={{ backgroundColor: "rgba(255,255,255,0.9)", paddingLeft: "32px", paddingRight: "32px" }}>
                        <div className="row">
                            <div className="col-md-4 d-none d-sm-none d-md-block align-self-center">
                                <img src={monito} className="img-fluid" alt="" ></img>
                            </div>
                            <div className="col-md-8 align-self-center">
                                <h1 className="display-3 text-dark">Postulación Exitosa</h1>
                                <h4><i className="fas fa-robot"></i> Se ha enviado tu postulación correctamente a los Talleres OCI Labs USM segundo semestre 2024</h4>
                                <p className="lead text-dark"> <i className="fas fa-angle-double-right icono"></i> Te llegará un correo de confirmación indicando que tu postulación fue ingresada exitosamente. <b>Recuerda que las clases para los nieveles Básico e Intermedio comienzan el viernes 26 de julio a las 18:00 hrs. por Discord.</b></p>
                                <p className="lead text-dark"> <i className="fas fa-angle-double-right icono"></i> Ya estás ingresado/a en el servidor de Discord de los talleres OCI Labs USM, puedes revisar el servidor ingresando a Discord con tu cuenta.</p>
                                <p className="lead mt-4 text-dark font-weight-bold"> <i className="fas fa-exclamation-circle icono"></i> 
                                    <b>IMPORTANTE:</b> si es que no puedes ver el servidor de Discord de los Talleres OCI Labs, envíanos un correo a contacto@ocilabs.cl indicando tu nombre y nivel del taller al que te inscribiste (básico, intermedio o avanzado).
                                </p>
                                <p className="lead text-dark"><i className="fas fa-angle-double-right icono"></i>Recuerda estar pendiente de la información que se irá subiendo en el servidor de Discord y en nuestro Instagram
                                    <a href="https://www.instagram.com/ocilabs/" target="_blank" rel="noopener noreferrer" style={{ color: "#c13584", marginLeft: "10px" }}><i className="fab fa-instagram"></i> ocilabs</a>
                                </p>
                                <p className="lead text-dark"> <i className="fas fa-angle-double-right icono"></i> Ante cualquier problema o duda, puedes contactarnos al correo <b>contacto@ocilabs.cl</b></p>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}