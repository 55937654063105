import React, { Component } from 'react'
import './Inscripcion4.css';
import fondo from '../images/banner2.png';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import monito from '../images/monito3.png';

export default class Inscripcion4 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="jumbotron-fluid paral fondo-cuadros" style={{ backgroundImage: 'url(' + fondo + ')', paddingTop: "40px", paddingBottom: "40px" }} >
                <div className="container">
                    <div className="alert alert-danger" style={{ paddingLeft: "32px", paddingRight: "32px" }}>
                        <h1 className="display-3" style={{color:"#721c24"}}>Algo salió mal...</h1>
                        <h4>Tu postulación a los Talleres OCI Labs USM no se pudo llevar a cabo<i className="fas fa-sad-tear icono"></i></h4>
                        <hr></hr>
                        <p className="lead" style={{color:"#721c24"}}> <i className="fas fa-angle-double-right icono"></i> Ponte en contacto con nosotros para que te ayudemos a completar tu postulación. Contáctanos a través de nuestro correo <b>contacto@ocilabs.cl</b> o a través de nuestro Instagram
                        <a href="https://www.instagram.com/ocilabs/" target="_blank" rel="noopener noreferrer" style={{ color: "#c13584", marginLeft: "10px" }}><i className="fab fa-instagram"></i> ocilabs</a>
                        </p>
                    </div>
                </div>
            </div>

        )
    }

}