import React from 'react';
import { Component } from 'react'
import Home from './views/Home';
import About from './views/About';
import Contenido from './views/Contenido';
import Material from './views/Material';
import Estudiantes from './views/Estudiantes';
import Inscripcion1 from './views/Inscripcion';
import Inscripcion2 from './views/Inscripcion2';
import Inscripcion3 from './views/Inscripcion3';
import Inscripcion4 from './views/Inscripcion4';
import SeleccionInscripcion from './views/SeleccionInscripcion';
import Estadisticas from './views/Estadisticas';

import logoScotia from './images/logos/logo-scotia-red-tm.svg';
import logoSCCC from './images/logos/logo-sccc.png'
import logoOCI from './images/logos/logo_OCI.png'
import logoDI from './images/logos/usm.png'
import logoOCILabs from './images/logos/logo-ocilabs.png'
import logoOCILabsWhite from './images/logos/logo-oci-white.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


class App extends Component {
  constructor(props) {
    super(props);
    this.handleChangeBasico = this.handleChangeBasico.bind(this);
    this.handleChangeIntermedio = this.handleChangeIntermedio.bind(this);
    this.handleChangeAvanzado = this.handleChangeAvanzado.bind(this);
    this.state = {basico: 'show active', intermedio: '',avanzado:''};
  }
  handleChangeBasico() {
    this.setState({basico:'show active',intermedio:'',avanzado:''});
  }
  handleChangeIntermedio() {
    this.setState({basico:'',intermedio:'show active',avanzado:''});
  }
  handleChangeAvanzado() {
    this.setState({basico:'',intermedio:'',avanzado:'show active'});
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <a id="arriba"></a>
          <div className="container-fluid " >
            <div className="d-none d-lg-block ">
              <div className="row redes-sociales bg-dark clearfix">
                <div className="container" >
                  <div className="text-light contenido float-left">
                    <a target="_blank" href="https://www.instagram.com/ocilabs/" style={{color:"white"}}>
                    <i className="fab fa-instagram icono"></i> ocilabs
                    </a>
                </div>
                  <div className="text-light float-right contenido">
                    <a target="_blank" href="https://www.youtube.com/c/OCILabs" style={{color:"white"}}>
                    <i className="fab fa-youtube icono"></i> OCI Labs
                    </a>
                </div>
                </div>
              </div>
            </div>
            <div className="row bg-light" >
              <div className="container" style={{ padding: "0px" }}>
                <div className="navbar-light bg-light ">
                  <nav className="navbar navbar-expand-lg " style={{ margin: "0px" }}>
                    <a className="sin-borde" href="/"><img src={logoOCILabs} alt="" width="" height="65"></img></a>
                    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div data-target="#navbarSupportedContent" className="collapse navbar-collapse" id="navbarSupportedContent">
                      
                    <ul className="navbar-nav ml-auto " >
                      <li className="nav-item" >
                        <Link to="/" className="nav-link"><i className="icono fas fa-home"></i>Inicio</Link>
                      </li>
                      {/*
                      <li className="nav-item">
                        <Link className="nav-link" to="/about">Sobre Nosotros</Link>
                      </li>
                      */}
                      {/*
                      <li className="nav-item">
                        <Link onClick={this.handleChangeBasico} className="nav-link" to="/estudiantes"><i className="icono fas fa-pen-alt"></i>Material Estudiantes</Link>
                      </li>
                      */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/material"><i className="icono fas fa-folder-open"></i>Material Docente</Link>
                      </li> 
                    </ul>
                    {/*
                    <form className="form-inline my-2 my-lg-0">
                      <button className="btn btn-secondary my-2 my-sm-0 btn-rounded" type="submit"><i className="fas icono fa-user-astronaut"></i> Ingreso Docentes</button>
                    </form>
                    */}
                    </div>
                    {/*
                    <form className="d-flex my-2 my-lg-0" style={{ marginRight: "14px", marginLeft:"10px" }}>
                      <Link to="/usm/postulacion"><button className="btn btn-primary my-2 my-sm-0 btn-rounded" type="submit"><i className="fas icono fa-robot"></i> Postulación Talleres</button></Link>
                    </form>
                    */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Switch>
              {/* 
              <Route path="/about">
                <About/>
              </Route>
              */} 
              <Route path="/material">
                <Material/>
              </Route>
              {/* 
              <Route path="/estudiantes/:curso/contenido/:id" children={<RedireccionarContenido/>}>
              </Route>
              <Route path="/estudiantes/intermedio">
                <Estudiantes basico="" intermedio="show active" avanzado=""/>
              </Route>
              <Route path="/estudiantes/avanzado">
                <Estudiantes basico="" intermedio="" avanzado="show active"/>
              </Route>
              <Route path="/estudiantes">
                <Estudiantes basico={this.state.basico} intermedio={this.state.intermedio} avanzado={this.state.avanzado}/>
              </Route>
              */}
              {/*
              <Route path="/usm/postulacion-fallida">
                <Inscripcion4 />
              </Route>
              <Route path="/usm/postulacion-exitosa">
                <Inscripcion3 />
              </Route>
              <Route path="/usm/postulacion-segunda-parte">
                <Inscripcion2 />
              </Route>
              <Route path="/usm/postulacion">
                <Inscripcion1 />
              </Route>
              
              <Route path="/seleccion-postulacion">
                <SeleccionInscripcion />
              </Route>
              */}
              <Route path="/admin/estadisticas">
                <Estadisticas />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
          <footer id="sticky-footer cuerpo" className="">
            <div className="container-fluid text-secondary" >
              <div className="container" style={{ paddingBottom: "24px" }}>
                <div className="row align-items-center" style={{ paddingTop: "15px", paddingBottom: "0px" }}>
                  <div className="patrocinadores">
                    <h5 className="display-4 align-self-center" style={{ fontSize: "23px", paddingTop: "19px", paddingLeft: "16px" }}>Organizadores </h5>
                  </div>
                </div>
                <div className="row py-4 justify-content-center align-items-center">
                  <div className="col-md-4">
                    <img src={logoDI} className="img-fluid" alt="" ></img>
                  </div>
                  <div className="col-md-3">
                    <img src={logoSCCC} className="img-fluid" alt="" ></img>
                  </div>
                  <div className="col-md-2">
                    <img src={logoOCI} className="img-fluid" alt="" ></img>
                  </div>
                  <div className="col-md-2">
                    <img src={logoScotia} className="img-fluid" alt="" ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid bg-dark text-white-50 py-4 ">
              <div className="container">
                <div className="row informacion-footer">
                  <div className="col-lg-8">
                    <div style={{ fontSize: "25px" }}>
                      <a className="sin-borde" href="/"><img src={logoOCILabsWhite} alt="" width="" height="55" style={{ opacity: .6 }} ></img></a>
                    </div>
                  </div>
                  <div className="col-lg-4 columna-contacto">
                    <h5 className="display-4">Contáctanos</h5>
                    <p>Encuéntranos nuestras redes sociales:</p>
                    <div style={{ paddingTop: "10px" }}>
                      <a href="https://www.youtube.com/channel/UCY_TZGUtc7Pr8b1_7_5v3Iw" target="_blank" rel="noopener noreferrer" className="fab icono fa-youtube fa-lg" style={{ color: "#c4302b" }}></a>
                      <a href="https://www.instagram.com/ocilabs/" target="_blank" rel="noopener noreferrer" className="fab icono fa-instagram fa-lg" style={{ color: "#c13584" }}></a>
                    </div>
                    <p>O a través de nuestro correo:</p>
                    <p><b>contacto@ocilabs.cl</b></p>
                  </div>
                </div>
              </div>
              <div className="container-fluid text-center" style={{ paddingTop: "20px" }}>
                <small>Desarrollado por
                  <a href="https://www.linkedin.com/in/mp-morales-llopis/" target="_blank" rel="noopener noreferrer" style={{ color: "rgba(255,255,255,0.5)" }}><i className="fas icono fa-hat-wizard"></i>  María Paz Morales</a> &
                  <a href="https://www.linkedin.com/in/vadokdev/" target="_blank" rel="noopener noreferrer" style={{ color: "rgba(255,255,255,0.5)" }}><i className="fas icono fa-music"></i> Gonzalo Fernández</a>
                </small>
              </div>
            </div>

          </footer>
        </Router>
      </React.Fragment>
    );
  }
}
export default App;

function RedireccionarContenido() {
  let {id,curso} = useParams();
  return (
    <Contenido id={id} curso={curso}/>
  );
}

