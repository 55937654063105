import React, { Component } from "react";
import "./Inscripcion2.css";
import fondo from "../images/banner2.png";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import regionesjson from "../data/regiones.json";
import cursosjson from "../data/grades.json";
import validators from "../validators/enrollmentFormValidator";
import EnrollmentService from "../services/EnrollmentService";
import Button from "react-bootstrap/Button";
import Rut from "rutjs";
import { Redirect } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale("es", es);

export default class Inscripcion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nivel: 0,
      sede: 16,
      link: null,
      value: "default",
      mix: 1,
      region: "default",
      colegio: "default",
      curso: "default",
      selected: null,
      selected2: null,
      selected3: null,
      basico: false,
      intermedio: false,
      avanzado: false,
      nombres: "",
      apellidos: "",
      email: "",
      rut: "",
      telefono: "",
      fecha: "",
      fechaDate: null,
      genero: "default",
      errores: [],
      accessToken: "",
      validacionExitosa: false,
      validacionFallida: false,
    };
    this.validacion = this.validacion.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    EnrollmentService.getAccessToken(code).then((codigo) => {
      this.setState({ accessToken: codigo.access_token });
    });
  }

  validacion() {
    const listaPirata = [];
    validators.map(({ field, fieldName, message, isValid }) => {
      if (!isValid(this.state[field])) {
        listaPirata.push({ field: fieldName, description: message });
      }
    });

    if (listaPirata.length == 0) {
      const rut = new Rut(this.state.rut);
      const formulario = {
        firstnames: this.state.nombres,
        lastnames: this.state.apellidos,
        rut: rut.getCleanRut(),
        email: this.state.email,
        phone: this.state.telefono,
        region: parseInt(this.state.region, 10),
        school: this.state.colegio,
        university: parseInt(this.state.sede, 10),
        gender: parseInt(this.state.genero, 10),
        mix: parseInt(this.state.mix, 10),
        workshop: parseInt(this.state.nivel, 10),
        grade: parseInt(this.state.curso, 10),
        birth: this.state.fecha,
      };
      EnrollmentService.sendEnrollmentForm(formulario, this.state.accessToken)
        .then((data) => {
          if (data.hasOwnProperty("status") && data.hasOwnProperty("message")) {
            this.setState({ validacionFallida: true });
          } else {
            this.setState({ validacionExitosa: true });
          }
        })
        .catch((error) => {
          this.setState({ validacionFallida: true });
        });
    } else {
      this.setState({ errores: listaPirata });
    }
  }

  updateInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const regiones = [
      { id: 15, name: "Región de Arica y Parinacota" },
      { id: 1, name: "Región de Tarapacá" },
      { id: 2, name: "Región de Antofagasta" },
      { id: 3, name: "Región de Atacama" },
      { id: 4, name: "Región de Coquimbo" },
      { id: 5, name: "Región de Valparaíso" },
      { id: 13, name: "Región Metropolitana de Santiago" },
      { id: 6, name: "Región del Libertador General Bernardo O’Higgins" },
      { id: 7, name: "Región del Maule" },
      { id: 16, name: "Región del Ñuble" },
      { id: 8, name: "Región del Biobío" },
      { id: 9, name: "Región de La Araucanía" },
      { id: 14, name: "Región de Los Ríos" },
      { id: 10, name: "Región de Los Lagos" },
      { id: 11, name: "Región de Aysén del General Carlos Ibáñez del Campo" },
      { id: 12, name: "Región de Magallanes y la Antártica Chilena" },
    ];
    return (
      <div
        className="jumbotron-fluid paral fondo-cuadros"
        style={{
          backgroundImage: "url(" + fondo + ")",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        {this.state.validacionExitosa && (
          <Redirect to="/usm/postulacion-exitosa" />
        )}
        {this.state.validacionFallida && (
          <Redirect to="/usm/postulacion-fallida" />
        )}
        <div className="container">
          <div
            className="card"
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              paddingLeft: "32px",
              paddingRight: "32px",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <h1
                  className="display-3 text-dark"
                  style={{ fontSize: "55px" }}
                >
                  Paso 2 - Postulación Talleres OCI Labs USM
                </h1>
                <p className="lead text-dark">
                  ¡Sólo queda un último paso para completar tu postulación en
                  los talleres OCI Labs USM en este segundo semestre de 2024!
                  Necesitamos que rellenes tu información personal, y selecciones a
                  qué nivel del taller te inscribirás.
                </p>
                <p className="lead text-dark font-weight-bold">
                  <i className="fas fa-exclamation-circle icono"></i>
                  <b>
                    IMPORTANTE: los talleres son exclusivamente para estudiantes desde 7º año de educación básica hasta 4º año de educación media que cumplan máximo 19 años al término del 2024. Antes de iniciar las clases, serán descartados todos aquellos que no cumplan con este requisito. 
                  </b>
                </p>
              </div>
            </div>
            <br></br>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <h5>Datos Personales del Estudiante</h5>
                  <hr></hr>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="nombre">Nombres</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombres"
                    onChange={this.updateInput}
                    placeholder="Primer y segundo nombre"
                  ></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="apellidos">Apellidos</label>
                  <input
                    type="text"
                    className="form-control"
                    id="apellidos"
                    onChange={this.updateInput}
                    placeholder="Escribe ambos apellidos"
                  ></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="email">Correo electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={this.updateInput}
                    placeholder="Escribe tu correo electrónico"
                  ></input>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="genero">
                    ¿Con cuál género te identificas?
                  </label>
                  <select
                    id="genero"
                    className="form-control"
                    onChange={(val) => {
                      if (val.target.value == 2) {
                        this.setState({ genero: val.target.value, mix: 1 }); //Cambiar a "default" si se quiere un paralelo solo mujeres
                      } else {
                        this.setState({ genero: val.target.value, mix: 1 });
                      }
                    }}
                  >
                    <option defaultValue value="default">
                      Selecciona...
                    </option>
                    <option value="2">Femenino</option>
                    <option value="1">Masculino</option>
                    <option value="3">Otro</option>
                    <option value="4">Prefiero no indicar</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="rut">RUT</label>
                  <input
                    type="text"
                    pattern="[0-9]{7,8}[-][0-9]{1}"
                    title="Debes poner el RUT sin puntos y con guión."
                    className="form-control"
                    id="rut"
                    onChange={this.updateInput}
                    placeholder="12345678-9"
                  ></input>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="fecha">Fecha de nacimiento</label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    locale="es"
                    selected={this.state.fechaDate}
                    onChange={(date) => {
                      if (date != null) {
                        const resultado = new Date(
                          date.getTime() - date.getTimezoneOffset() * 60 * 1000
                        );
                        if (resultado != null) {
                          this.setState({
                            fechaDate: date,
                            fecha: resultado.toISOString().split("T")[0],
                          });
                        } else {
                          this.setState({ fechaDate: null, fecha: "" });
                        }
                      } else {
                        this.setState({ fechaDate: null, fecha: "" });
                      }
                    }}
                    className="form-control"
                    placeholderText="DD-MM-AAAA"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {/* 
                  <input type="date" className="form-control" id="fecha" onChange={this.updateInput}></input>
                  */}
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="phone">Número de celular</label>
                  <div className="input-group mb-2">
                    <span className="input-group-text">+56</span>
                    <input
                      type="number"
                      pattern="[0-9]{9,10}"
                      className="form-control"
                      id="telefono"
                      onChange={this.updateInput}
                      placeholder="Escribe tu número"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-5">
                  <label htmlFor="nivel">
                    Selecciona la región en la que estudias
                  </label>
                  <Typeahead
                    id="region"
                    labelKey="name"
                    onChange={(selected) => {
                      if (selected.length != 0) {
                        if (
                          regionesjson["regions"][selected[0].id][
                            "universities"
                          ].length == 1
                        ) {
                          this.setState({
                            region: selected[0].id,
                            sede: regionesjson["regions"][selected[0].id][
                              "universities"
                            ][0].id,
                          });
                        } else {
                          this.setState({ region: selected[0].id });
                        }
                      } else {
                        this.setState({
                          region: "default",
                          selected2: null,
                          sede: "default",
                        });
                      }
                    }}
                    options={regiones}
                    selected={this.state.selected}
                    placeholder="Selecciona una región..."
                    emptyLabel="No hay regiones con ese nombre."
                    clearButton={true}
                    paginationText="Cargar más regiones..."
                  />
                </div>
                <div className="form-group col-md-5">
                  <label htmlFor="colegio">Colegio en el que estudias</label>
                  <input
                    type="text"
                    className="form-control"
                    id="colegio"
                    onChange={this.updateInput}
                    placeholder="Indica tu colegio"
                  ></input>
                  <div className="form-text">
                    Si es que rindes exámenes libres, indícalo aquí.
                  </div>
                </div>
                {/**
                <div className="form-group col-md-5">
                  <label htmlFor="nivel">Colegio</label>
                  <Typeahead
                    id="colegio"
                    labelKey="name"
                    onChange={(selected) => {
                      if (selected.length != 0) {
                        this.setState({ colegio: selected[0].id });
                      } else {
                        this.setState({ colegio: "default" });
                      }
                    }}
                    options={
                      this.state.region != "default"
                        ? regionesjson["regions"][this.state.region]["schools"]
                        : []
                    }
                    selected={this.state.selected2}
                    placeholder={
                      this.state.region != "default"
                        ? "Escribe o busca tu colegio..."
                        : "Debes seleccionar una región primero..."
                    }
                    emptyLabel="No hay colegios con ese nombre."
                    clearButton={true}
                    paginationText="Cargar más colegios..."
                    disabled={this.state.region != "default" ? false : true}
                  />
                </div>

                **/}
                <div className="form-group col-md-2">
                  <label htmlFor="curso">Curso</label>
                  <select
                    id="curso"
                    className="form-control"
                    onChange={(val) => {
                      this.setState({ curso: val.target.value });
                    }}
                  >
                    <option defaultValue value="default">
                      Selecciona...
                    </option>
                    {cursosjson.map((x, index) => {
                      return (
                        <option key={index} value={x.id}>
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h5>Datos del Taller</h5>
                  <hr></hr>
                </div>
              </div>
              <div className="form-row">
                {/**this.state.genero == "2" &&
                                    <div className="form-group col-md-4">
                                        <label htmlFor="nivel">¿Preferirías estar en un paralelo sólo mujeres?</label>
                                        <select id="mix" className="form-control" onChange={(val) => {
                                            this.setState({ mix: val.target.value })
                                        }
                                        }>
                                            <option defaultValue value="default">Selecciona...</option>
                                            <option value="2">Paralelo sólo mujeres</option>
                                            <option value="1">Paralelo mixto</option>
                                        </select>
                                    </div>
                                }
                                {this.state.region != "default" && regionesjson["regions"][this.state.region]["universities"].length > 1 &&
                                    <div className="form-group col-md-5">
                                        <label htmlFor="sede">Selecciona una sede</label>
                                        <select id="sede" className="form-control" onChange={(val) => {
                                            this.setState({ sede: val.target.value })
                                        }
                                        }>
                                            <option defaultValue value="default">Selecciona...</option>
                                            {regionesjson["regions"][this.state.region]["universities"].map((x, index) => {
                                                return (
                                                    <option key={index} value={x.id}>{x.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                        **/}
              </div>
              <label htmlFor="nivel">
                
                Las clases de los tres niveles son los viernes de 18:00 a
                20:00 hrs. a través de Discord (modalidad 100% online). <b>Las clases para los niveles Básico e Intermedio comienzan el viernes 26 de julio.</b>
                {/*
                En esta oportunidad, <b>el nivel Avanzado sólo 
                está abierto para estudiantes que ya pasaron por el nivel Intermedio.</b>
                
                Las clases son los viernes entre 18:00 y 20:00 hrs. a través de
                Discord (modalidad online).{" "}
                <b>Las clases comienzan el viernes 21 de julio. </b>
                <b>
                  Solo quedan cupos para ingresar al Taller Básico desde la
                  segunda clase (desde el viernes 28 de julio).
                </b>{" "}
                Cupos llenos para los Talleres Intermedio y Avanzado.
                */}
              </label>
              <label htmlFor="nivel">
                Haz clic en una de las siguientes tarjetas para seleccionar el
                nivel del taller al que quieres postular:
              </label>
              <div className="form-row ">
                <div className="col-md-4 d-flex align-items-stretch">
                  <Button
                    style={{
                      padding: "20px",
                      marginBottom: "32px",
                      color: "#212529",
                    }}
                    onClick={() => {
                      this.setState({
                        nivel: 1,
                        basico: true,
                        intermedio: false,
                        avanzado: false,
                      });
                    }}
                    active={this.state.basico}
                    variant={"btn btn-block btn-outline-warning"}
                  >
                    <h5>
                      <i className="fas fa-robot icono"></i>Taller Básico
                    </h5>
                    <hr></hr>
                    Pensado para quienes quieren introducirse en el mundo de la
                    programación desarrollando minijuegos en
                    <a
                      href="https://scratch.mit.edu/about"
                      target="_blank"
                      style={{ color: "#212529" }}
                    >
                      <b> Scratch</b>
                    </a>
                    . No requiere conocimientos previos.{" "}
                    <b>
                      Dura 13 semanas en modalidad online (taller semestral).
                    </b>{" "}
                    <br></br>
                    <br></br>
                    <small>
                      {this.state.basico
                        ? "Seleccionado"
                        : "Haz clic para seleccionar"}
                    </small>
                  </Button>
                </div>
                <div className="col-md-4 d-flex align-items-stretch">
                  <Button
                    //disabled //dejar comentado si se quiere habilitar
                    style={{
                      padding: "20px",
                      marginBottom: "32px",
                      color: "#212529",
                    }}
                    onClick={() => {
                      this.setState({
                        nivel: 2,
                        basico: false,
                        intermedio: true,
                        avanzado: false,
                      });
                    }}
                    active={this.state.intermedio}
                    variant={"btn btn-block btn-outline-success"}
                  >
                    <h5>
                      <i className="fas fa-keyboard icono"></i>Taller Intermedio
                    </h5>
                    <hr></hr>
                    
                    Pensado para quienes quieran introducirse en la programación
                    desarrollando distintas actividades entretenidas en C++. 
                    No requiere conocimientos previos. <b>Dura 13 semanas en modalidad online (taller semestral).</b>
                    {/*
                    <b> ¡Cupos llenos!</b>
                    <br></br>
                    Lamentablemente, ya se llenaron los cupos para el taller
                    Intermedio. Abriremos una nueva postulación para el primer
                    semestre de 2024, ¡mantente pendiente!
                    */}
                    <br></br>
                    <br></br>
                    <small>
                      {this.state.intermedio
                        ? "Seleccionado"
                        : "Haz clic para seleccionar"}
                    </small>
                  </Button>
                </div>
                <div className="col-md-4 d-flex align-items-stretch">
                  <Button
                    disabled //dejar comentado si se quiere habilitar
                    style={{
                      padding: "20px",
                      marginBottom: "32px",
                      color: "#212529",
                    }}
                    onClick={() => {
                      this.setState({
                        nivel: 3,
                        basico: false,
                        intermedio: false,
                        avanzado: true,
                      });
                    }}
                    active={this.state.avanzado}
                    variant={"btn btn-block btn-outline-primary "}
                  >
                    <h5>
                      <i className="fas fa-terminal icono"></i>Taller Avanzado
                    </h5>
                    <hr></hr>
                    
                    <b> ¡Cupos llenos!</b>
                    <br></br>
                    Lamentablemente ya se llenaron los cupos para el taller
                    Avanzado. Abriremos una nueva postulación para el primer
                    semestre de 2025, ¡mantente pendiente!
                    
                    {/*
                    Pensado para quienes quieran profundizar sus conocimientos
                    de programación en C++ e introducirse en el mundo de la programación competitiva, a través del uso de algoritmos y
                    estructuras de datos avanzadas. 
                    
                    En esta oportunidad es exclusivamente para los estudiantes que <b> ya pasaron por el nivel Intermedio. </b> 
                    <b>Dura 13 semanas en modalidad online (taller semestral).</b>
                    
                    <b> Requiere conocimientos básicos de programación en C++</b>, 
                    recomendado para quienes ya pasaron por el Taller Intermedio. <b>Dura 26 semanas en modalidad online (taller anual)</b>.
                    */}
                    <br></br>
                    <br></br>
                    {/* //descomentar si se quiere habilitar
                    <small>
                      
                      {this.state.avanzado
                        ? "Seleccionado"
                        : "Haz clic para seleccionar"}
                    </small>
                    */}
                  </Button>
                </div>
              </div>
              {this.state.errores.length != 0 && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <strong>
                    <i className="fas fa-robot icono"></i>Beep boop...¡Alto ahí
                    humano!
                  </strong>{" "}
                  Debes revisar que estén bien ingresados los siguientes campos:
                  <ul className="noInferiorBorder">
                    {this.state.errores.map((error, index) => (
                      <li key={index}>
                        <b>{error.field}</b>: {error.description}{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div align="center" style={{ paddingBottom: "32px" }}>
                <button
                  type="button"
                  onClick={this.validacion}
                  style={{ paddingLeft: "15px" }}
                  className={"btn text-white btn-primary"}
                >
                  Enviar <i className="fas icono fa-paper-plane"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
