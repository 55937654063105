import axios from 'axios';

const StatisticService = {
    getWorkshopsGeneralInfo() {
        return axios.get(process.env.REACT_APP_BACKEND_URL + '/api/statistics/')
            .then((response) => {
                return response.data;
            }).catch( (error) => { 
                console.log(error);
                return "error";
            });
    },
    getWorkshopsInfoWithFilters() {
        return axios.get(process.env.REACT_APP_BACKEND_URL + '/api/statistics/filtered')
            .then((response) => {
                return response.data;
            }).catch( (error) => { 
                console.log(error);
                return "error";
            });
    }
};

export default StatisticService;
