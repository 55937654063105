import React, { Component } from 'react'
import './Home.css';
import fondo from '../images/fondo5.png';
import basico from '../images/logosDiscord/basico.png';
import intermedio from '../images/logosDiscord/intermedio.png';
import avanzado from '../images/logosDiscord/avanzado.png';
import modalidad from '../images/logosDiscord/general.png';
import general from '../images/logosDiscord/logo.png';
import monito1 from '../images/monito1.png';
import monito2 from '../images/monito2.png';

export default class Home extends Component {
    render() {
        return (
            <section>
                <div className="jumbotron paralhome" style={{ backgroundImage: 'url(' + fondo + ')' }}>
                    <div className="container-fluid" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-none d-md-block align-self-center">
                                <img src={monito2} className="img-fluid" alt="" ></img>
                            </div>
                            <div className="col-md-6 align-self-center" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                
                                <h1 className="text-light">¡Gracias por tu interés en los talleres!</h1>
                                <p className="text-light" style={{ fontSize: "24px" }}>
                                    Lamentablemente ya se llenaron los cupos para esta edición segundo semestre de 2024. El primer semestre de 2025 se hará una nueva convocatoria, ¡esperamos verte en esa oportunidad!
                                </p>
                                <p className="text-light" style={{ fontSize: "18px" }}>
                                    A nombre de todo el equipo OCI Labs, esperamos contar contigo el próximo semestre <i className="far icono fa-smile-wink"></i>
                                </p>
                               
                                {/*
                                <h1 className="text-light">¡Postula a nuestros talleres!</h1>
                                <p className="text-light" style={{ fontSize: "24px" }}>
                                    ¡Aprende a programar con nosotros en nuestros distintos talleres gratuitos y 100% online para estudiantes desde 7° Básico hasta 4° Medio!
                                </p>
                                <p className="text-light" style={{ fontSize: "18px" , margin: "0px" }}>
                                    Te invitamos a participar de esta nueva versión de los Talleres OCI Labs 2024-2. En esta oportunidad, se abren las postulaciones para el segundo semestre en la sede de la Universidad Técnica Federico Santa María. ¡No pierdas la oportunidad de postularte y sumergirte en el mundo de la programación! 
                                </p>
                                <br/>
                                <p className="text-light" style={{ fontSize: "18px", margin: "0px" }}>
                                    
                                     <b><i className="fas fa-exclamation-circle icono"></i>¡IMPORTANTE! Postulaciones extendidas hasta el lunes 22 de julio. <br/>Nuevo Inicio de Clases para los talleres Básico e Intermedio: viernes 26 de julio.</b> 
                                    
                                </p>       
                                <br/>
                                <p className="text-light" style={{ fontSize: "18px", margin: "0px" }}>
                                    
                                     ¡Postulaciones abiertas para los niveles Básico e Intermedio! Cupos llenos para el nivel Avanzado. Conoce más sobre los distintos niveles y requisitos de postulación aquí. 
                                     {/*
                                     ¡Postulaciones abiertas para el nivel Básico! (entrando desde la segunda clase en adelante). Cupos llenos para los Niveles Intermedio y Avanzado. Conoce más sobre los distintos niveles y formas de postulación aquí.
                                     
                                </p>                                
                                <div style={{ marginTop: "20px" }}>
                                    <a href="/usm/postulacion"><button type="button" className="btn btn-lg btn-primary"><i className="fas fa-terminal icono"></i>Postula aquí! </button></a>
                                </div>
                                */}
                            </div>
                            <div className="col-md-3 d-none d-sm-none d-md-block align-self-center">
                                <img src={monito1} className="img-fluid" alt="" ></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid fondoPatron">
                    <div className="container">
                        <div className="row informacion1">
                            <div className="d-none d-lg-block col-md-3 align-self-center" align="center">
                                <img alt="" className="rounded-circle img-fluid shadow" src={basico} width="180" height="180" />
                            </div>

                            <div className="col-lg-9 col-md-12">
                                <div className="container" align="right">
                                    <h2 className="" style={{ fontSize: "2.2rem" }}>Taller Básico</h2>
                                    <p style={{ fontSize: "18px" }}>
                                        El Taller Básico tiene como finalidad introducir a los estudiantes en el mundo de la programación mediante el desarrollo de distintos minijuegos utilizando el lenguaje visual de programación en bloques Scratch. Está enfocado en estudiantes que no tienen ningún conocimiento previo en programación, y que desean crear historias, animaciones y juegos mientras se introducen en el mundo de la informática y desarrollan el pensamiento computacional.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row informacion2">
                            <div className="col-lg-9 col-md-12">
                                <div className="container">
                                    <h2 className="" style={{ fontSize: "2.2rem" }}>Taller Intermedio</h2>
                                    <p style={{ fontSize: "18px" }}>
                                        El Taller Intermedio tiene como finalidad introducir a los estudiantes en la programación en lenguaje C++, mediante el desarrollo de distintos algoritmos y actividades entretenidas en código. Este taller está enfocado en estudiantes que no tienen conocimientos previos de programación, y que desean aprender a programar escribiendo código mientras aprenden a resolver problemas de manera lógica. Recomendado también si es que ya pasaste por el Taller Básico, y quieres seguir profundizando tus conocimientos con nosotros.
                                    </p>
                                </div>
                            </div>
                            <div className="d-none d-lg-block col-md-3 align-self-center" align="center">
                                <img alt="" className="rounded-circle shadow" src={intermedio}  width="180" height="180" />
                            </div>
                        </div>
                        <div className="row informacion3">
                            <div className="d-none d-lg-block col-md-3 align-self-center" align="center">
                                <img alt="" className="rounded-circle shadow" src={avanzado} width="180" height="180" />
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <div className="container" align="right">
                                    <h2 className="" style={{ fontSize: "2.2rem" }}>Taller Avanzado</h2>
                                    <p style={{ fontSize: "18px" }}>
                                        El Taller Avanzado tiene como finalidad profundizar los conocimientos en programación utilizando el lenguaje C++, mediante el desarrollo de programas que impliquen la implementación y uso de estructuras de datos y algoritmos avanzados. Este taller está enfocado en estudiantes que hayan pasado por el Taller Intermedio, o que tengan conocimientos básicos en programación en C++ equivalentes a los entregados en el Taller Intermedio, y que quieran seguir mejorando sus conocimientos de programación adentrándose en el mundo de la programación competitiva.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row informacion2">
                            <div className="col-lg-9 col-md-12">
                                <div className="container">
                                    <h2 className="" style={{ fontSize: "2.2rem" }}>Sobre la modalidad de las clases</h2>
                                    <p style={{ fontSize: "18px" }}>
                                        En este segundo semestre de 2024 la modalidad de las clases será 100% online a través de la plataforma Discord. Las clases son los <b>viernes de 18:00 a 20:00 hrs.</b> Los talleres Básico e Intermedio son semestrales y tienen una duración de 13 clases, <b>comenzando el viernes 26 de julio.</b> Los y las estudiantes se dividirán en distintos paralelos, cada uno guiado por un tutor o tutora, que se encargará de apoyarlos en las distintas actividades y etapas del aprendizaje. 
                                    </p>
                                </div>
                            </div>
                            <div className="d-none d-lg-block col-md-3 align-self-center" align="center">
                                <img alt="" className="rounded-circle shadow" src={modalidad}  width="180" height="180" />
                            </div>
                        </div>
                        <div className="row informacion3">
                            <div className="d-none d-lg-block col-md-3 align-self-center" align="center">
                                <img alt="" className="rounded-circle shadow" src={general} width="180" height="180" />
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <div className="container" align="right">
                                    <h2 className="" style={{ fontSize: "2.2rem" }}>Sobre los talleres</h2>
                                    <p style={{ fontSize: "18px" }}>
                                        Los Talleres OCI Labs vienen realizándose desde 2019 con la misión de acercar la programación a estudiantes escolares de todo el país. A lo largo de estos 6 años han pasado más de 5.000 estudiantes por nosotros, y estamos orgullosos de poder seguir llegando a todas las regiones de Chile para introducir a más alumnos y alumnas en el pensamiento computacional. Es por esto que continuamos mejorando y puliendo año a año la modalidad de nuestras clases online, para guiar todos y todas quienes quieran adentrarse en el mundo de la programación.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*
                <div className="container-fluid" style={{paddingBottom:"90px",paddingTop:"80px",backgroundColor:"#e9ecef"}}>                        
                    <div className="container ">
                        <h2 className="display-4" style={{fontSize:"3rem"}}>Herramientas <strong>Docentes</strong></h2>
                        <p  className="lead ">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                        <hr></hr>
                        <div className="row informacion1">
                            <div className="d-none d-lg-block col-md-3  imagen-robot" style={{paddingLeft:"55px"}}>
                                <i className="fas fa-folder-open fa-9x"></i>
                            </div>
                            <div className="col-md-9">
                                <div className="container ">
                                    <h2 className="display-4" style={{fontSize:"2.2rem"}}>Repositorio para profesores</h2>
                                    <p >This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
                                    <div style={{marginTop:"0px"}}>
                                        <a href="/material"><button type="button" className="btn btn-info text-white">Ver el repositorio <i className="fas fa-chevron-right icono"></i> </button></a>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
                <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner ">
                        <div className="carousel-item active">
                            <img alt="" className="d-block w-100 " src="https://www.adslzone.net/app/uploads/2017/12/lenguajes-de-programaci%C3%B3n-empresas-2018.jpg" ></img>
                            <div id="overlay" className="overlay" >
                                <div className="carousel-caption text-left d-none d-md-block ">
                                    <h2>Noticia 1</h2>
                                    <p>Texto y descripción de la noticia 1. Texto y descripción de la noticia 1. Texto y descripción de la noticia 1. Texto y descripción de la noticia 1. Texto y descripción de la noticia 1. Texto y descripción de la noticia 1. Texto y descripción de la noticia 1.</p>
                                    
                                </div>
                            </div>  
                        </div>
                        <div className="carousel-item">
                            <img alt="" className="d-block w-100" src="https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849821_960_720.jpg" ></img>
                            <div className="overlay" >
                                <div className="carousel-caption text-left d-none d-md-block ">
                                    <h2>Noticia 2</h2>
                                    <p>Texto y descripción de la noticia 2. Texto y descripción de la noticia 2. </p>
                                </div>
                            </div> 
                        </div>
                        <div className="carousel-item">
                        <img alt="" className="d-block w-100" src="https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849825_960_720.jpg"></img>
                        <div className="overlay">
                                <div className="carousel-caption  text-left d-none d-md-block ">
                                    <h2>Noticia 3</h2>
                                    <p>Texto y descripción de la noticia 3. </p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                */}
            </section >
        )
    }

}
