import React, { Component } from 'react'
import './Inscripcion.css';
import fondo from '../images/banner2.png';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import regionesjson from '../data/regiones.json';
import cursosjson from '../data/grades.json';
import monito from '../images/monito.png';


export default class Inscripcion extends Component {
    render() {
        return (
            <div className="jumbotron-fluid paral fondo-cuadros" style={{ backgroundImage: 'url(' + fondo + ')', paddingTop: "40px", paddingBottom: "40px" }} >
                <div className="container">
                    <div className="card text-dark" style={{ backgroundColor: "rgba(255,255,255,0.9)", paddingLeft: "32px", paddingRight: "32px" }}>
                        <h1 className="display-3 text-dark" style={{fontSize:"55px"}}>Paso 1 - Postulación Talleres OCI Labs USM</h1>
                        <h4 className="display-3 text-dark" style={{fontSize:"45px"}}>Autentificación Discord</h4>
                        <p className="lead text-dark">
                            ¡Te damos la bienvenida a los talleres online OCI Labs USM!
                        </p>
                        <p className="lead text-dark" style={{paddingTop:"10px"}}>
                            La modalidad de las clases será 100% online los <b>viernes de 18:00 a 20:00 hrs.</b> Los talleres Básico e Intermedio son semestrales y tienen una duración de 13 clases, <b>comenzando el viernes 26 de julio.</b>
                        </p>
                        <p className="lead text-dark" style={{paddingTop:"10px"}}>
                            <i className="fas fa-exclamation-circle icono"></i> Postulaciones abiertas para los niveles Básico e Intermedio. <b>Cupos llenos para el nivel Avanzado.</b> 
                        </p>
                        <p className="lead text-dark" style={{paddingTop:"10px"}}>
                            Los talleres se realizarán a través de la plataforma <a href="https://discord.com/" target="_blank">Discord</a>,
                            por lo que es necesario que tengas una cuenta de Discord para poder participar.
                        </p>
                        <p className="lead text-dark" style={{paddingTop:"10px"}}> 
                            Te dejamos la siguiente <strong>cápsula</strong> para que entiendas cómo es el <strong>proceso de postulación</strong>, y conozcas la plataforma Discord, en la que se realizan nuestros Talleres:
                        </p>
                        <br></br>
                        <p align="center" style={{textAlign: 'center'}}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/UM_GANNlFB8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </p>
                        <br></br>
                        <div className="row ">
                            <div className="col-md-9">
                                <p className="h4 text-dark">¡Vamos con el primer paso!</p>
                                <p className="lead text-dark"> Al hacer click en el botón de abajo, serás redirigido/a a un <span className="font-weight-bold">formulario de autorización de Discord</span>. </p>
                                <p className="lead mt-4 text-dark"> <i className="fas fa-angle-double-right icono"></i>Deberás ingresar al formulario con tu usuario y contraseña de Discord, o crear una nueva cuenta en caso de no tener alguna. </p>
                                <p className="lead mt-4 text-dark font-weight-bold"> <i className="fas fa-exclamation-circle icono"></i> 
                                    <b>IMPORTANTE: debes ingresar a Discord con la cuenta que usarás como estudiante para unirte a las clases online.</b>
                                </p>
                                <p className="lead text-dark"> <i className="fas fa-angle-double-right icono"></i>Una vez ingresado/a, deberás darnos algunas autorizaciones para poder ingresarte en el servidor correcto del taller.</p>
                                <br></br>
                                <p className="lead mt-4 text-dark"> <i className="fas fa-info-circle icono"></i> Una vez que autorices a OCI Labs, serás redirigido/a al <span className="font-weight-bold">segundo paso de la postulación</span>, en el que deberás rellenar un formulario con tus datos personales, y elegir el nivel del taller en el cual quieres participar.</p>
                            </div>
                            <div className="col-md-3 d-none d-sm-none d-md-block align-self-center">
                                <img src={monito} className="img-fluid" alt="" ></img>
                            </div>
                        </div>
                        <div align="center" style={{ paddingBottom: "32px" }}>
                            <a href={process.env.REACT_APP_DISCORD_OAUTH_URL}>
                                <button type="button" className={"btn text-white btn-primary mt-2"}>Ir a la autentificación de Discord <i className="fab icono fa-discord"></i></button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}