import React, { Component } from "react";
import "./Inscripcion.css";
import fondo from "../images/banner2.png";
import "react-bootstrap-typeahead/css/Typeahead.css";
import monito from "../images/monito.png";

export default class Inscripcion extends Component {
  render() {
    return (
      <div
        className="jumbotron-fluid paral fondo-cuadros"
        style={{
          backgroundImage: "url(" + fondo + ")",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="container">
          <div
            className="card text-dark"
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              paddingLeft: "32px",
              paddingRight: "32px",
            }}
          >
            <h1 className="display-3 text-dark" style={{ fontSize: "55px" }}>
              Postulaciones Abiertas - Talleres OCI Labs
            </h1>
            <h4 className="display-4 text-dark" style={{ fontSize: "45px" }}>
              ¡Conoce las Distintas Sedes Disponibles!
            </h4>
            <p className="lead text-dark">
              ¡Estamos muy contentos con tu interés por participar en nuestros
              talleres! Los talleres, este año, se realizarán simultáneamente en
              distintas sedes. A continuación, te mostramos las sedes
              disponibles a las que puedes postular.
            </p>
            <br></br>
            <div className="row ">
              <div className="col-md-6 d-flex align-items-stretch">
                <div className="card shadow-sm rounded mb-5 ">
                  <div className="card-body ">
                    <h5 className="card-title">Talleres OCI en el Bío Bío</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Postulaciones abiertas hasta el 13 de abril
                    </h6>
                    <p className="card-text text-dark">
                      La Universidad Católica de la Santísima Concepción, la
                      Universidad del Bío-Bío y la Universidad de Concepción en
                      conjunto realizarán los Talleres OCI en el Bío Bío. Las
                      postulaciones están abiertas para el nivel{" "}
                      <b>Intermedio</b>, que no requiere conocimientos previos
                      de programación. Los talleres se realizarán en modalidad
                      online, en dos horarios disponibles: viernes de 17:00 a
                      19:00 hrs, y sábados de 11:00 a 13:00 hrs.
                    </p>
                    <p
                      className="card-text text-dark"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Las postulaciones para esta sede están abiertas a
                      estudiantes de la Región del Biobío hasta el 13 de abril.
                      Las clases comienzan el viernes 22 de abril, y los
                      talleres son completamente gratuitos.
                    </p>
                    <a
                      href="https://forms.gle/Qaab1vY6Dunp3xg88"
                      target="_blank"
                    >
                      <button type="button" className="btn btn-primary">
                        <i className="fas fa-terminal icono"></i>Ir a la
                        Postulación Talleres OCI en el Bío Bío{" "}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch">
                <div className="card shadow-sm rounded mb-5">
                  <div className="card-body">
                    <h5 className="card-title">Talleres OCI Labs USM</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Postulaciones abiertas hasta el 20 de abril
                    </h6>
                    <p className="card-text text-dark">
                      La Universidad Técnica Federico Santa María impartirá los
                      Talleres OCI Labs en el Campus Casa Central Valparaíso y
                      en el Campus San Joaquín USM, en tres niveles distintos;
                      los niveles <b>Básico</b> e <b>Intermedio</b> no necesitan
                      conocimientos previos en programación, y se impartirán
                      durante 12 semanas en modalidad online, los viernes de
                      18:00 a 20:00 hrs. El nivel <b>Avanzado</b> requiere
                      conocimientos previos de programación en C++ y se
                      impartirá en modalidad híbrida durante 24 semanas (clases
                      online los viernes de 18:00 a 20:00 hrs., y algunas
                      semanas con competencias presenciales los sábados de 10:00
                      a 12:00 hrs).{" "}
                    </p>
                    <p
                      className="card-text text-dark"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Las postulaciones para esta sede están abiertas a
                      estudiantes de todo Chile hasta el 20 de abril. Las clases
                      de los tres niveles comienzan el viernes 22 de abril a
                      través de Discord. Los talleres son completamente
                      gratuitos.
                    </p>
                    <a href="/usm/postulacion">
                      <button type="button" className="btn btn-primary">
                        <i className="fas fa-terminal icono"></i>Ir a la
                        Postulación Talleres OCI Labs USM{" "}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
