import React, { Component } from 'react'
import './Estadisticas.css';
import fondo from '../images/banner2.png';
import StatisticService from '../services/StatisticService';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const nombresFiltro = {
    sede: "Sede",
    region: "Región",
    genero: "Género",
    establecimiento: "Tipo de Establecimiento"

}

const tabla1inicio = {
    basico: {
        inscritos: 0,
        porcentaje: "0%",
        mujeres: 0
    },
    intermedio: {
        inscritos: 0,
        porcentaje: "0%",
        mujeres: 0
    },
    avanzado: {
        inscritos: 0,
        porcentaje: "0%",
        mujeres: 0
    },
    total: 0
}

const tabla2inicio = [
    {
        filtro: "sede",
        info: []
    },
    {
        filtro: "region",
        info: []
    },
    {
        filtro: "genero",
        info: []
    },
    {
        filtro: "establecimiento",
        info: []
    }
]

export default class Inscripcion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabla1: tabla1inicio,
            tabla2: tabla2inicio,
            filtro: "sede",
        }
        this.onClickTable1 = this.onClickTable1.bind(this);
    }

    componentDidMount() {
        StatisticService.getWorkshopsGeneralInfo().then((info) => {
            if (info != "error") {
                this.setState({ tabla1: info });
            }
        });
        StatisticService.getWorkshopsInfoWithFilters().then((info) => {
            if (info != "error") {
                this.setState({ tabla2: info });
            }
        });
    }

    onClickTable1() {
        StatisticService.getWorkshopsGeneralInfo().then((info) => {
            if (info != "error") {
                this.setState({ tabla1: info });
            }
        });
        StatisticService.getWorkshopsInfoWithFilters().then((info) => {
            if (info != "error") {
                this.setState({ tabla2: info });
            }
        });
    }

    render() {
        return (
            <div className="jumbotron-fluid paral fondo-cuadros" style={{ backgroundImage: 'url(' + fondo + ')', paddingTop: "40px", paddingBottom: "40px" }
            } >
                <div className="container">
                    <div className="card" style={{ backgroundColor: "rgba(255,255,255,1)", paddingLeft: "32px", paddingRight: "32px", paddingBottom: "20px" }}>
                        <h1 className="display-3 texto-gris">Estadísticas de Registro Estudiantes</h1>
                        <hr></hr>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="col-md-10">
                                <p className="h4 texto-gris">Estadísticas Generales por Taller</p>
                            </div>
                            <div className="col-md-2 text-right">
                                <button type="button" onClick={this.onClickTable1} className="btn btn-primary d-block d-md-inline-block">Refrescar <i className="fas fa-sync icono"></i></button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm" >
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Taller</th>
                                        <th scope="col">Inscritos</th>
                                        <th scope="col">% del Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Básico</td>
                                        <td>{this.state.tabla1.basico.inscritos}</td>
                                        <td>{this.state.tabla1.basico.porcentaje}</td>
                                    </tr>
                                    <tr>
                                        <td>Intermedio</td>
                                        <td>{this.state.tabla1.intermedio.inscritos}</td>
                                        <td>{this.state.tabla1.intermedio.porcentaje}</td>
                                    </tr>
                                    <tr>
                                        <td>Avanzado</td>
                                        <td>{this.state.tabla1.avanzado.inscritos}</td>
                                        <td>{this.state.tabla1.avanzado.porcentaje}</td>
                                    </tr>
                                </tbody>
                                <thead className="thead-light">
                                    <tr>
                                        <th>Total Inscritos</th>
                                        <th>{this.state.tabla1.total}</th>
                                        <th>100.00%</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <br></br>
                        {/*
                        <p className="h4 texto-gris">Estadísticas por Filtro</p>
                        <form className="form-inline" style={{ marginBottom: "10px" }}>
                            <label className="my-1 mr-2" for="inlineFormCustomSelectPref">Filtrar por</label>
                            <select className="form-control my-1 mr-sm-2 pr-5" id="inlineFormCustomSelectPref" onChange={(e) => {
                                this.setState({ filtro: e.target.value })
                            }}>
                                <option defaultValue value="sede">Sede</option>
                                <option value="region">Región</option>
                                <option value="genero">Género</option>
                                <option value="establecimiento">Tipo de Establecimiento</option>
                            </select>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm" >
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">{nombresFiltro[this.state.filtro]}</th>
                                        <th scope="col">Taller</th>
                                        <th scope="col">Inscritos</th>
                                        <th scope="col">Total {nombresFiltro[this.state.filtro]}</th>
                                        <th scope="col">% del Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tabla2.find(element => element.filtro === this.state.filtro).info.map((x, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td rowSpan="3" className="align-middle">{x.nombre}</td>
                                                    <td>Básico</td>
                                                    <td>{x.basico}</td>
                                                    <td rowSpan="3" className="align-middle">{x.total}</td>
                                                    <td rowSpan="3" className="align-middle">{x.porcentaje}</td>
                                                </tr>
                                                <tr>
                                                    <td>Intermedio</td>
                                                    <td>{x.intermedio}</td>
                                                </tr>
                                                <tr>
                                                    <td>Avanzado</td>
                                                    <td>{x.avanzado}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                                <thead className="thead-light">
                                    <tr>
                                        <th colSpan="3">Total Inscritos</th>
                                        <th>{this.state.tabla1.total}</th>
                                        <th>100.00%</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        */}
                    </div>
                </div>
            </div >

        )
    }

}