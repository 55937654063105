import axios from "axios";

const EnrollmentService = {
  sendEnrollmentForm(form, accesToken) {
    return axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/signup",
        {
          birth: form.birth,
          email: form.email,
          firstnames: form.firstnames,
          gender: form.gender,
          grade: form.grade,
          lastnames: form.lastnames,
          mix: form.mix,
          phone: form.phone,
          region: form.region,
          rut: form.rut,
          school: form.school,
          university: 16,
          workshop: form.workshop,
        },
        {
          headers: {
            "Discord-Token": accesToken,
          },
        }
      )
  },
  getAccessToken(code) {
    return axios
      .post(process.env.REACT_APP_BACKEND_URL + "/discord/login", {
        code: code,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
};

export default EnrollmentService;
